import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Slider from "react-slick"
import uuid from 'uuid'
import ReactBnbGallery from 'react-bnb-gallery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';

import useWindowSize from '../utils/useWindowSize';

import Layout from '../components/Layout'
import SEO from "../components/seo"
import BgImage from "../components/BgImage"
import BookingForm from '../components/BookingForm';

import "slick-carousel/slick/slick.css" 
import "slick-carousel/slick/slick-theme.css"

const DesertCamp = ({data}) => {

  const windowSize = useWindowSize(767)

  const [galleryOpened, setGalleryOpened] = useState(false)
  const [activePhotoIndex, setActivePhotoIndex] = useState(0)


  const images = data.camps.edges[0].node.frontmatter.images

  const gallery_pics = images.map(({childImageSharp}) => {
    return {
      photo: childImageSharp.fluid.srcWebp
    }
  }) 

  return (
    
    <Layout
      sidebarComponent={<BookingForm colWidth="col-md-12" productType="Desert camp"/>}
      className="detail-page desert-camp-page"
    >
        
      <SEO title="Luxury desert camp Merzouga" />
    
      {
        (windowSize.windowWidth > 1000) &&
      
        <section className="row-2-cols">
          <div className="one-half" onClick={() => { setActivePhotoIndex(0); setGalleryOpened(true)} }>
            <BgImage className='detail-pic detail-pic-big' image={images[0]} />
          </div>
    
          <div className="one-half">
            <div onClick={() => { setActivePhotoIndex(1); setGalleryOpened(true)} }>
              <BgImage className='detail-pic' image={images[1]} />
            </div>
            <div className="row-2-cols">
              <div className="one-half" onClick={() => { setActivePhotoIndex(2); setGalleryOpened(true)} }>
                <BgImage className='detail-pic' image={images[2]} />
              </div>
              <div className="one-half last-pic" onClick={() => { setActivePhotoIndex(3); setGalleryOpened(true)} }>
                <BgImage className='detail-pic' image={images[3]} />
                {
                  images.length > 4 &&
                  <div className="more-pics">
                    <span>+ { images.length - 4 } pictures</span>
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      }

      {
        (windowSize.windowWidth <= 1000) &&

        <Slider
          dots={true}
          slidesToScroll={1}
          slidesToShow={1}
          className="detail-slider"
        >
          {images.map((slide) => (
            <div onClick={() => setGalleryOpened(true) } key={uuid.v4()}>
              <BgImage
                className='detail-pic'
                image={slide}
              />
            </div>
          ))}
        </Slider>  
      }
  
      
      <div className="detail-page-header">
        <div className="row">
          <div className="col-md-12">
            <h1>Luxury desert camp Merzouga</h1>
          </div>        
        </div>
      </div>
  
      <section className="row-2-cols detail-description">
        <div className="one-half">
          <div className="inner-content">
  
            <p>The camp has a maximum capacity of 15 people. Each Tent has been decorated in a personalized way so that our guests feel at home in a different and idyllic place. The materials used have been made by hand in Morocco.</p>
  
            <p>Please inquire for our special rates for groups, and discounts for younger children. Ask about our rental rates for the entire camp for your event.</p>

            <div className="pricing-title">Pricing</div>

            <div className="pricing-table">
              <div className="pricing-item">
                <div className="pricing-price">100€</div>
                <div className="pricing-item-title">Single Tent</div>
              </div>
              <div className="pricing-item">
                <div className="pricing-price">160€</div>
                <div className="pricing-item-title">Double Tent</div>
              </div>
              <div className="pricing-item">
                <div className="pricing-price">220€</div>
                <div className="pricing-item-title">Triple Tent</div>
              </div>
              <div className="pricing-item">
                <div className="pricing-price">300€</div>
                <div className="pricing-item-title">Quadruple Tent</div>
              </div>
            </div>

          </div>
        </div>
        <div className="one-half">
          <div className="inner-content">
            <div className="camp-features-wrapper inner-content">
              <h2>Features and Amenities</h2>  
              <ul className="camp-features">
                <li>✓ Transfer by 4×4 or dromedary to and from the riad</li>
                <li>✓ Room service</li>
                <li>✓ Private showroom</li>
                <li>✓ Private bathroom</li>
                <li>✓ Dinner in the Room</li>
                <li>✓ Breakfast in the Room</li>
                <li>✓ Hot water</li>
                <li>✓ Sand bath</li>
                <li>✓ Sand boarding</li>
              </ul>
            </div> 
          </div>
        </div>
      </section>
      
      <div className="inner-content">
        <ul className="image-gallery about-us-gallery columns-3">
        {images.map((slide, idx) => (
          <li key={uuid.v4()}>
            <div onClick={() => { setActivePhotoIndex(idx); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
            <BgImage
              className='detail-pic'
              image={slide}
            />
          </li>
        ))}
        </ul>
      </div>

      <ReactBnbGallery
        show={galleryOpened}
        photos={gallery_pics}
        onClose={() => setGalleryOpened(false)}
        activePhotoIndex={activePhotoIndex}
      />

      
        
    </Layout>
  
  ) 
}

export default DesertCamp 

export const DesertCampPageQuery = graphql`
  query DesertCampPageQuery {
    camps: 
      allMarkdownRemark (
        filter: {
          frontmatter: {type: {eq: "Desert camp"}}
        }
        sort: { order: DESC, fields: [frontmatter___date]}
      ) {
        edges {
          node {
            id
            frontmatter {
              images {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
  }
`